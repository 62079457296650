import React, { PureComponent } from 'react';
import { LineChart, Line,Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';

// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const data = [
//   {
//     name: 'Aug',
//     bookings: 0,
//     // : 1,
//     amt: 2400,
//   },
//   {
//     name: 'Sep',
//     bookings: 3,
//     // : 5,
//     amt: 2210,
//   },
//   {
//     name: 'Oct',
//     bookings: 1,
//     // : 3,
//     amt: 2290,
//   },
//   {
//     name: 'Nov',
//     bookings: 2,
//     // : 2,
//     amt: 2000,
//   }, 
//   {
//     name: 'Dec',
//     bookings: 1,
//     // : 4,
//     amt: 2181,
//   }
// ];


export default function Propertychart({body}) {
    // console.log(JSON.stringify(body),"the data......")
    const data=body
    // console.log(JSON.stringify(data),"the data......")
    return (
      <div style={{ width: '100%', height: 300 , marginTop: '20px', minWidth: '1000px'}}>
        {console.log(data, "I am data")}
      <ResponsiveContainer >
        <BarChart
          width={500}
          height={300}
          data={data}
          
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="bookings" fill="#8884d8" />
          {/* <Bar dataKey="Cancelled" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
      </div>
    );
  
}
