//ReportDetails
import React from 'react'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { ToastContainer, toast } from "react-toastify";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate ,useLocation} from "react-router-dom";
// import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import { Block, DeleteOutline, Email, RemoveRedEye } from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { Avatar } from "@mui/material";
import UserBlock from "../../components/modal/UserBlock";
import DeleteUser from "../../components/modal/DeleteUser";
import moment from "moment/moment";
import server from "../../server";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Toptag from '../../components/topTag/Toptag';

const columns = [
  { id: "profile_pic", label: "", align: "right", minWidth: 12 },
  { id: "name", label: "Name", minWidth: 100 },
  {
    id: "report",
    label: "report text",
    minWidth: 180,
    align: "left"
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 150,
    align: "right",
  },
];

function ReportDetails() {
  const redirect=useNavigate()
  const location = useLocation();
  const { id } = location.state;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUsersData] = useState([]);
  const [total_docs, setTotal] = useState(0);
  const [block_view, setBlock] = useState(false);
  const [delete_view, setDelete] = useState(false);
  const [b_userId, setUserId] = useState("");
  const [d_userId, setDlUserId] = useState("");
  const [block_status,setblockStatus]= useState()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getData();
  };
  useEffect(() => {
    getData(page, rowsPerPage,id);
  }, [page, rowsPerPage,id]);
  const getData = async () => {
    await axios
      .get(server.live_dev+"/reported_list", {
        headers: { "x-token": sessionStorage.getItem("x_token") },
        params: { pagenumber: page, limit: rowsPerPage ,userId:id },
      })
      .then((res) => {
        // alert(res.data.code)
        if(res.data.code===203){
          toast.error(res.data.message)
          sessionStorage.clear()
           setTimeout(()=> window.location.reload(true),1000)
        }else if(res.data.code===201){
          toast.error(res.data.message)
        }else if(res.data.code===200){
          setUsersData(res.data.data);
          if (res.data.data.length > 0) {
            setIsLoading(true);
            setTotal(res.data.totalDocuments);
          }
        }else{
          toast.error("Something went wrong!")
        }
       
      });
  };
  return (
   <>
   {isLoading === false ? (
        <Loader />
      ) : (
        <div className="container-fluid">
        <Toptag />
        <ToastContainer />
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 530 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {user_data.map((row) => {
               return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  {/* https://th.bing.com/th/id/OIP.7c6wkFQ9XQ2jIjGYZZALrwHaE7?pid=ImgDet&rs=1 */}
                  <TableCell>
                    <Avatar
                      alt="Remy Sharp"
                      src={row.profile_pic ==="" ? server.user_static: server.live_image+row.profile_pic}
                    />
                  </TableCell>
                  <TableCell>{row.full_name}</TableCell>
                  <TableCell>{row.text}</TableCell>
                  <TableCell align="right">
                          {/* <Tooltip title="view profile">
                            <RemoveRedEye className="visibilityIc" />
                          </Tooltip> */}
                          <Link
                            to={`/users/details`}
                            state={{ id: row.userId }}
                          >
                            <Tooltip title="view profile">
                              <RemoveRedEye className="visibilityIc" />
                            </Tooltip>
                          </Link>
                          {/* <Tooltip
                            title={
                              row.adminBlock === false
                                ? "block user"
                                : "unblock user"
                            }
                          >
                            {row.adminBlock === false ?  
                              <Block
                              className={`block ${"block"}`}
                              id="isActive"
                              value={row.adminBlock}
                              // onClick={() => hanldeBlock(row._id,row.adminBlock)}
                              // color="error"
                            />
                            : <LockOpenIcon
                            className={`block ${"block"}`}
                            id="isActive"
                            value={row.adminBlock}
                            // onClick={() => hanldeBlock(row._id,row.adminBlock)}
                            // color="error"
                          />}
                            
                          </Tooltip> */}
                          {/* <Tooltip title="Delete User">
                            <DeleteOutline
                              className="userListDelete"
                              // onClick={() => hanldeDelete(row._id)}
                            />
                          </Tooltip> */}
                        </TableCell>
                  </TableRow>
               )
            })}
            </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={total_docs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Paper>
        </div>
        
      )}
   </>
  )
}

export default ReportDetails