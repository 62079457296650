import React, { useState } from "react";
import "../../pages/category/categories.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import server from "../../server";
import { useNavigate } from "react-router-dom";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Grid, Tooltip } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";
import { useEffect } from "react";
import Propertychart from "../chart/Propertychart";

function Graph({ isDeleteOpened, onClick, CloseDialog, body ,id,graph_data , graph_data_cancelled}) {
    const history = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [question, setQuestion] = useState("");
    const [answer,setAnswer]=useState("")
    const [cid, setCid] = React.useState("");
    const [item_view, setItemView] = useState(false);
    const [categories, setCategories] = useState([]);
    const [itemImage,setItemImage]= useState("")
    console.log(graph_data , "--------------->Graph data",id)
    console.log(graph_data_cancelled , "Cancelled Booking data")
  //   console.log(body,"the body",age)
  
  //   const handleChange = (event) => {
  //     setCid(event.target.value);
  //     console.log(event.target.value,"the value")
  //     if(event.target.value===""){
  //         setItemView(false);
  //     }else{
  //         setItemView(true);
  //     }
      
  //   };
  
    console.log(question, "this is that");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const handleClose = () => {
      //setOpen(false);
      CloseDialog(false);
      setItemView(false);
      setCid("");
    };
  //   useEffect(() => {
  //     axios
  //       .get(server.dev + "/category_for_dropdown", {
  //         headers: { x_token: sessionStorage.getItem("x_token") },
  //       }).then((res)=>{
  //         if(res.data.code===200){
  //             setCategories(res.data.data)
  //         }
  //       })
  //   },[]);
  
    const addNew = async () => {
     
    //   if(question.trim()==="") return  toast.error("White spaces are not allowed")
      // if (category.trim().length <= 0)
      //   return toast.error("Add item name white spaces are not allowed");
      // if(itemImage==="") return toast.error("Image is not allowed to be empty")
      // if(price<0 || price ==="") return toast.error("Price is mandatory and it should not be a negative number")
      //   const formdata=new FormData()
      //   formdata.append("file",itemImage)
      //   formdata.append('item',category)
      //   formdata.append('categoryId',cid)
      //   formdata.append('price',price)
    //   axios.post(server.live_dev+"/addpropertytype",{property_type:question},{headers:{"x-token":sessionStorage.getItem("x_token")}}).then((res)=>{
    //       if(res.data.code===203){
    //         toast.error(res.data.message) 
    //         sessionStorage.clear()
    //         setTimeout(()=> window.location.reload(true),1000)
    //       }else if(res.data.code===201){
    //         toast.error(res.data.message)
    //       }else if(res.data.code===200){
    //         toast.success(res.data.message)
    //         setItemImage("")
    //         setAnswer("")
    //         setQuestion("")
    //         setItemView(false)
    //         CloseDialog(false);
    //         onClick()
    //       }else{
    //         toast.error("Something went wrong !")
    //       }
    //     })
        
    };
  
    // const onImage=(e)=>{
    //   console.log(e.target.files[0],"the file changes")
    //   if(e.target.files[0].size>3145728){
    //     toast.error("File size should be less than 3 mb")
    //   }else{
    //     setItemImage(e.target.files[0])
    //   }
    // }
  
    const questions=(event)=>{
      setQuestion(event.target.value.trim)
      // if(category!=="" && price !==""){
      //     setItemView(true)
      // }
    }
    
    return (
        <div>
        <Dialog
          fullScreen={fullScreen}
          open={isDeleteOpened}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        //   md={{ minWidth: "200px" }}
        >
          <DialogTitle id="responsive-dialog-title">
            {"Monthly Booking graph"}
          </DialogTitle>
  
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">
              Select one category
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={cid}
              label="Select one category"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {body.map((row)=>(
                  <MenuItem value={row._id}>{row.title}</MenuItem>
              ))} */}
              {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            {/* </Select>
          </FormControl> */}
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Autocomplete
              id="grouped-demo"
              options={options.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.title}
              sx={{ width: "auto" }}
              renderInput={(params) => (
                <TextField {...params} label="With categories" />
              )}
              renderGroup={(params) => (
                <li>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
          </FormControl> */}
          {/* {item_view === true ? ( */}
            <DialogContent style={{maxWidth : "100%"}}>
            <Propertychart body={graph_data}/>
            </DialogContent>
          {/* ) : (
            ""
          )} */}
  
          <DialogActions style={{maxWidth : "100%"}}>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
            {/* {item_view === true ? ( */}
            
            {/* ):""} */}
          </DialogActions>
        </Dialog>
      </div>
  )
}

export default Graph