import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const data = [
  {
    name: '1',
    Successful: 4,
    Cancelled: 2,
    amt: 7,
  },
  {
    name: '2',
    Successful: 2,
    Cancelled: 4,
    amt: 3,
  },
  {
    name: '3',
    Successful: 6,
    Cancelled: 2,
    amt: 8,
  },
  {
    name: '4',
    Successful: 9,
    Cancelled: 1,
    amt: 4,
  },
  {
    name: '5',
    Successful: 8,
    Cancelled: 2,
    amt: 9,
  }
];
export default function Barchart() {

    return (
      <div style={{ width: '100%', height: 350,  marginTop: '20px' , minWidth: '450px'}}>
      <ResponsiveContainer >
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Successful" fill="#8884d8" />
          <Bar dataKey="Cancelled" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
      </div>

    );
  
}
