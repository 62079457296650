// import './userdetail.css';
import Toptag from '../../components/topTag/Toptag';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SwipeableTextMobileStepper from '../../components/carousel/Carousel';
import { ToastContainer, toast } from 'react-toastify';

export default function Userdetail() {

    const id = '6311e5e5b2ad550b45fc2eb7'
    console.log(id, "idddddddde")

    const [one, two] = useState({
        license_img: ['a.jpg', 'b.jpg'],
        licence_verification: 0
    });

    const handleChange = (event) => {
        two({
            ...one,
            [event.target.id]: event.target.value,
        });

    };



    const updateLicense = async (event) => {

        event.preventDefault();
        const updateLicStat = await axios.post('https://admin.parknb.com/admin/verifyLicense', { id: one._id, licence_verification: one.licence_verification })
        if (updateLicStat.data.status) {
            toast.success('License Verification Status Updated !', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error('Error !', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    // useEffect(() => {
    //     const getData = async () => {
    //         const ff = await axios.get('https://admin.parknb.com/admin/getUserDetails', { params: { id: id } });
    //         console.log(ff.data, "shopdetail")
    //         two(ff.data.data)
    //     }
    //     getData();
    // }, [])

    return (
        <div className='container-fluid'>
            <Toptag />
            <div className='mainBoxx'>
                <div className="centerBox1">
                    <div className="spanHeading">
                        <span className="detailTitle1">Shop Details</span>
                    </div>
                    <div className="centerBox">
                        <div className="imgCon12">
                            <SwipeableTextMobileStepper licImg={one.license_img} />
                            <div className="licUpCont">
                                <div className="labeliDiv">
                                    <label className='licupLabel'>License Verification</label>
                                </div>
                                <div className="dropDown">
                                    <select className="form-control" value={one.licence_verification} id='licence_verification' onChange={handleChange}  >
                                        <option hidden value={0}>Not Uploaded</option>
                                        <option hidden value={1}>Pending</option>
                                        <option value={2}>Verify</option>
                                        <option value={3}>Reject</option>
                                    </select>
                                </div>
                                <div className="btnDiv">

                                    <button className='licSubmit' disabled={one.license_img ? false : true} onClick={updateLicense}>Update Status</button>
                                </div>
                            </div>
                        </div>
                        <div className="centerline">

                        </div>
                        <div className="fieldsCont">
                          
                            <div className="spanContainer">
                                <span className="userShowTitle">Business Details</span>
                            </div>

                            <div className="infoTable">
                                <label className='labelCont'>License Number</label>
                                <input type="text" readOnly disabled value={one.licenseNumber} className="userInput" />
                            </div>
                            <div className="infoTable">
                                <label className='labelCont'>Website</label>
                                <input type="text" readOnly disabled value={one.website} className="userInput" />
                            </div>
                            <div className="infoTable">
                                <label className='labelCont'>Shop Number</label>
                                <input type="text" readOnly disabled value={one.shop_number} className="userInput" />
                            </div>
                            <div className="infoTable">
                                <label className='labelCont'>Timings</label>
                                <input type="text" readOnly disabled value={one.timings} className="userInput" />
                            </div>
                            <div className="infoTable">
                                <label className='labelCont'>Floors</label>
                                <input type="text" readOnly disabled value={one.timings} className="userInput" />
                            </div>
                            <div className="infoTable">
                                <label className='labelCont'>Sections</label>
                                <input type="text" readOnly disabled value={one.timings} className="userInput" />
                            </div>

                            <div className="spanContainer">
                                <span className="userShowTitle">Owner Details</span>
                            </div>

                            <div className="infoTable">
                                <label className='labelCont'>Name</label>
                                <input type="text" readOnly disabled value={one.name} className="userInput" />
                            </div>
                            <div className="infoTable">
                                <label className='labelCont'>User Name</label>
                                <input type="text" readOnly disabled value={one.user_name} className="userInput" />
                            </div>
                            <div className="infoTable">
                                <label className='labelCont'>Email</label>
                                <input type="text" readOnly disabled value={one.email} className="userInput" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
