import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import server from "../../server";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";

const ChartAnalysis = () => {
  const redirect = useNavigate();
  const [data, two] = useState({ sellers: "", buyers: "", shops: "" });

  let formatter = Intl.NumberFormat("en", { notation: "compact" });
  // toast.error(one)
  useEffect(() => {
    const getData = async () => {
      const ff = await axios.get(server.live_dev + "/dashboard", {
        headers: { "x-token": sessionStorage.getItem("x_token") },
      });
      // alert(ff.data.code)
      console.log(ff, "This is ffffffffffffffffffff");
      if (ff.data.code === 203) {
        sessionStorage.clear();
        toast.error(ff.data.message);
        // window.location.reload(true);
        setTimeout(() => window.location.reload(true), 1000);
      } else if (ff.data.code === 201) {
        toast.error(ff.data.message);
      } else {
        two([
          { name: "Bookings", value: ff.data.results.total_bookings },
          { name: "Cancelled", value: ff.data.results.cancelled.count },
        ]);
      }
    };
    getData();
  }, []);

  const centerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(to right, #fdcbf1, #fdcbf1 50%, #e6dee9 50%)",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#2d3436",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    width: "100%",
    maxWidth: "100%",
  };

  const chartContainerStyle = {
    width: "100%",
    height: "300px",
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`Value: ${formatter.format(value)}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return (
    <div style={centerStyle}>
      <h2 style={{ marginBottom: "20px" }}>Analysis</h2>
      <div style={chartContainerStyle}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label={({
                cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                value,
                index,
              }) => {
                const RADIAN = Math.PI / 180;
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                return (
                  <text
                    x={x}
                    y={y}
                    fill="#8884d8"
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central"
                  >
                    {data[index].name}: {formatter.format(data[index].value)}
                  </text>
                );
              }}
              activeShape={renderActiveShape} // Use the custom active shape
            >
              <Cell key="total_bookings" fill="#82ca9d" />
              <Cell key="cancelled" fill="#ff6464" />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ChartAnalysis;
