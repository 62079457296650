import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import axios from 'axios';
import server from '../../server';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

export default function UserBlock({ isDialogOpened, handleCloseDialog,onClick ,body ,block_status}) {
  const history = useNavigate()
    // console.log(props,"dddddd")
  // const [open, setOpen] = React.useState(props === true ? true :false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  console.log(block_status,"fffff")
  // const handleClickOpen = () => {
  //   setOpen(props);
  // };

  const handleClose = () => {
    //setOpen(false);
    handleCloseDialog(false);
  };

  const block_submit = (id) =>{
    axios.post(server.live_dev + "/adminblock",{userId:id},{headers:{"x-token":sessionStorage.getItem("x_token")}}).then((res)=>{
        if(res.data.code===203){
            sessionStorage.clear()
           toast.error(res.data.message)
            setTimeout(()=> window.location.reload(true),1000)
        }else if(res.data.code===201){
            toast.error(res.data.message)
        }else{
            handleClose()
            onClick()
            toast.success(res.data.message)
        }
    })
  } 

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isDialogOpened}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {block_status === true ? "Would you like to unblock this user?" : "Would you like to block this user?" }
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={()=>block_submit(body)} autoFocus>
          {block_status === true ? "Unblock" : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}