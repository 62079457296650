import React from 'react'
import './amenity.css'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { ToastContainer, toast } from "react-toastify";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate ,useLocation} from "react-router-dom";
// import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import { Block, DeleteOutline, Email, RemoveRedEye, Search ,Edit} from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { Avatar } from "@mui/material";
import UserBlock from "../../components/modal/UserBlock";
import DeleteUser from "../../components/modal/DeleteUser";
import moment from "moment/moment";
import server from "../../server";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Toptag from '../../components/topTag/Toptag';
import EditAmenities from '../../components/modal/EditAmenities';
import Addamenity from '../../components/modal/Addamenity';
import Deleteamenity from '../../components/modal/Deleteamenity';
import Addvehicle from '../../components/modal/Addvehicle';
import Editvehicle from '../../components/modal/Editvehicle';
import Deletevehicle from '../../components/modal/Deletevehicle';

const columns = [
  { id: "s/n", label: "S/N", align: "left", minWidth: 1 },
  { id: "vehicles", label: "Vehicle", minWidth: 150 },
  {
    id: "actions",
    label: "Actions",
    minWidth: 30,
    align: "center",
  },
];
function Vehicles() {
    const redirect=useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUsersData] = useState([]);
  const [total_docs, setTotal] = useState(0);
  const [block_view, setBlock] = useState(false);
  const [delete_view, setDelete] = useState(false);
  const [b_userId, setUserId] = useState("");
  const [d_userId, setDlUserId] = useState("");
  const [block_status,setblockStatus]= useState()
  const [ami,setAmi]=useState("")
  const [amiId,setAmiid]=useState("")
  const [search,setSearch]= useState("")
  const [create_view, setCreate] = useState(false);
  console.log(user_data,"the foking data")
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getData();
  };
  useEffect(() => {
    getData(page, rowsPerPage,search);
  }, [page, rowsPerPage,search]);
  const getData = async () => {

    var skip = search =="" ? page : 0
    var limit = search ==""  ? rowsPerPage : total_docs
    console.log(skip,"sfhsdkfj",limit)


    await axios
      .get(server.live_dev+"/getVehicles", {
        headers: { "x-token": sessionStorage.getItem("x_token") },
        params: { pagenumber: skip, limit: limit ,search:search},
      })
      .then((res) => {
        // alert(res.data.code)
        if(res.data.code===203){
          toast.error(res.data.message)
          sessionStorage.clear()
           setTimeout(()=> window.location.reload(true),1000)
        }else if(res.data.code===201){
          toast.error(res.data.message)
        }else if(res.data.code===200){
          setUsersData(res.data.data);
          if (res.data.data.length > 0) {
            setIsLoading(true);
            setTotal(res.data.totalDocuments);
          }
        }else{
          toast.error("Something went wrong!")
        }
       
      });
  };
  const openEdit=(title,id)=>{
    setAmi(title)
    setAmiid(id)
    setBlock(true)
  }
  const hanldeDelete = (id,ami) => {
    setDlUserId(id);
    setDelete(!delete_view);
    setAmi(ami)
    getData();
  };
  let sn=page * rowsPerPage + 1 ; 
  return (
    <>
    {isLoading === false ? (
        <Loader />
      ) : (
        <>
        <Toptag />
        <ToastContainer />
        <div className="container-fluid"  style={{marginBottom:"30px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        
        <div className="searchBarcategorys">
            <input
              type="text"
              placeholder="Search... "
              // onKeyDown={handleKeyDown}
              id="searchtext"
              onChange={(e)=>setSearch(e.target.value)}
              className="searchBarInputcate"
            />
            <Search className="searchIconcate" />
            
          </div>
          <div><button className="addbtn" onClick={()=>setCreate(!create_view)}>Add New Vehicle</button></div>
        </div>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 530 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {user_data.map((row) => {
               return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  {/* https://th.bing.com/th/id/OIP.7c6wkFQ9XQ2jIjGYZZALrwHaE7?pid=ImgDet&rs=1 */}
                  <TableCell>
                    {sn++}
                  </TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="center">
                         <Tooltip title="edit">
                            <Edit onClick={()=>openEdit(row.title,row._id)}/>
                         </Tooltip>
                         <Tooltip title="Delete vehicle">
                            <DeleteOutline
                              className="userListDelete"
                              onClick={() => hanldeDelete(row._id,row.title)}
                              style={{marginLeft:"15px"}}
                            />
                          </Tooltip>
                        </TableCell>
                  </TableRow>
               )
            })}
            </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={total_docs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Paper>
            <Editvehicle 
            isDeleteOpened={block_view}
            body={ami}
            id={amiId}
            onClick={getData}
            CloseDialog={() => setBlock(false)}
            />
             <Addvehicle
            isDeleteOpened={create_view}
            body={d_userId}
            onClick={getData}
            CloseDialog={() => setCreate(false)}
          />
           <Deletevehicle
                    isDeleteOpened={delete_view}
                    body={d_userId}
                    amenity={ami}
                    onClick={getData}
                    CloseDialog={() => setDelete(false)}
                  />
        </>
        
      )
    }
    </>
  )
}

export default Vehicles