import './sellerDetail.css';
import Toptag from '../../components/topTag/Toptag';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect,useRef, useState } from 'react';
import axios from 'axios';

import Tooltip from '@mui/material/Tooltip';

import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../components/loader/Loader'
import server from '../../server';
import PhoneInput , {CountryData} from 'react-phone-input-2'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VerifiedIcon from '@mui/icons-material/Verified';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { Grid } from '@mui/material';
import Closegroup from '../../components/modal/VerifyId';
import VerifyId from '../../components/modal/VerifyId';
import Superhost from '../../components/modal/Superhost';


export default function SellerDetail() {
  const redirect= useNavigate()
  const location = useLocation();
  const { id } = location.state;
  console.log(id, "id coming here")
  const [edit, setEdit] = useState('disabled');
  const [edit1, setEdit1] = useState(false);
  const [one, two] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode,setCountryCode]=useState("")
  const [mobile,setMobile]=useState("")
  const inputFile = useRef(null);
  const [localpic,setLocalpic]=useState("")
  const [updatedPic,setPic]= useState("")
  const [pIm,setPim] =useState("")
  const [delete_view, setDelete] = useState(false);
  const [delete_viewhost, setDeletehost] = useState(false);
  const [d_userId, setDlUserId] = useState("");
  // const [c_name,setCname] = useState("")

  const editFunction = () => {
    setEdit('')
    setEdit1(true)
    window.scrollTo({   top: document.documentElement.scrollHeight, behavior: 'smooth' }); 
  }

  const hide =()=>{
    setEdit('disabled')
    setEdit1(false)
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    setLocalpic(pIm)
  }

  const handleKeyDown = e => {
    console.log(e.target.value)
    if (e.target.value === '') {
      if (e.key === " ") {
        e.preventDefault();
      }
    }
  };

  const inputEvent = (e) => {
    const { id, value } = e.target;
    // alert("coming")
    two((prevalue) => {
      return {
        ...prevalue,
        [id]: value
      };
    });
  };

  const updateData = () => {
    console.log('updated')
  }

  const hanldeDelete = (id) => {
    setDlUserId(id);
    setDelete(!delete_view);
    getData();
  }

  const hanldeDeletehost = (id) => {
    setDlUserId(id);
    setDeletehost(!delete_viewhost);
    getData();
  }

  const updateUserdata = (e) => {
    // alert("here")
    e.preventDefault();

    let obj = { full_name: one.full_name,designation:one.designation,about:one.about,address_name:one.address_name, _id: one._id,profile_photo: updatedPic,countryCode:countryCode ==="" ? "": "+" + countryCode,contact:mobile}
    console.log(obj,"the object-------------------------->")
    const formdata= new FormData()
    formdata.append("full_name",obj.full_name)
    formdata.append('file',obj.profile_photo)
    formdata.append('countryCode',obj.countryCode)
    formdata.append('contact',obj.contact)
    formdata.append('userId',obj._id)
    // formdata.append('address_name',obj.address_name)
    formdata.append('about',obj.about)
    formdata.append('designation',obj.designation)
    

    axios.post(server.live_dev+"/update_user",formdata).then((res)=>{
      if(res.data.code===203) {
        toast.error(res.data.message)
        sessionStorage.clear()
        setTimeout(()=> window.location.reload(true),1000)
      } else if (res.data.code===201){
        toast.error(res.data.message)
      } else {
        toast.success(res.data.message)
        getData()
      }
    })
      // alert(obj)
    console.log(formdata,"blah blah")
   
  }

  const getData = async () => {
    const ff = await axios.get(server.live_dev+'/getUsersDetails', { headers:{"x-token":sessionStorage.getItem("x_token")} ,params: { userId: id } });
    
    if(ff.data.code===203){
      toast.error(ff.data.message)
      sessionStorage.clear()
      setTimeout(()=> window.location.reload(true),1000)
    }else if(ff.data.code===201){
      toast.error(ff.data.message)
    }else{
      console.log(ff, "thi is ff coming ")
      two(ff.data.results)
      var photo=ff.data.results.profile_pic
      
      if(photo===""){
        setLocalpic(server.user_static)
        setPim(server.user_static)
      }else{
        setLocalpic(server.live_image+photo)
        setPim(server.live_image+photo)
      }
      if (ff) { setIsLoading(false); }
    }
    

  }
  console.log(localpic,"the geneuis-------------------->")
  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [])

  // alert(`${one.profile_photo}`)
  const setphoneuse=async(data,country)=>{
    // console.log(data.slice(country.dialCode.length),"fffff",country)
    setCountryCode(country.dialCode)
    setMobile(data.slice(country.dialCode.length))
  }

  const onButtonClick = () => {
    console.log(inputFile, "file");
    console.log(typeof edit1, "down edit1");
    console.log(edit1 === "disabled", "down edit1");
    inputFile.current.click();
  };

  const handleChange = (e) => {
    console.log(e.target.files[0], "threegggggggggggggggggggggggggg");
    if (e.target.files.length > 0) {
      let filename = e.target.files[0];
      setLocalpic(URL.createObjectURL(filename))
      setPic(filename)
      setPim(localpic)
      // four({
      //   localPic: URL.createObjectURL(filename),
      //   pic: filename.name,
      //   file: filename,
      // });
    }
  };
return (
  <>
  {isLoading
      ?
      <Loader />
      :
     <div className='container-fluid'>
  <Toptag />
  <ToastContainer />


  <div className="userDetCont">

      <div className="userTitleContainer">
          <span className="detailTitle">User Details</span>
         <button className='userAddButton' hidden={edit1} onClick={editFunction} >Edit</button>
      </div>


      <div className="mainBoxx">
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Grid item  spacing={1}>
            <span >Profile Photo</span>
            {
                edit1 === true ? 
                <>
                {/* <div className="image-upload">
              <label for="image">
              <Tooltip title="Change your profile pic"><AddPhotoAlternateIcon  style={{fontSize:"30px",color:"grey",cursor:"pointer"}}/></Tooltip>
              </label>

              <input id="image" hidden  type="file" onChange={handleChange}/>
              
              </div> */}
              <input id="image" hidden type="file" ref={inputFile} onChange={handleChange} />

                </> : <></> 
              }
            <div className="imGCont">
            
            {/* <img src={localpic==="" ? "/images/blank_pic.png" :localpic }  className='myImg' alt="" /> */}

            <img src={localpic==="" ? "/images/blank_pic.png" :localpic } className='myImg' alt="" onClick={onButtonClick} />

            </div>
           
            <span >Government Id</span>
            <div className="imGCont">
            <img src={one.ID==="" ? server.user_static: server.live_image+one.ID}  className='myImg' alt="" />
            </div>
          
            </Grid>
            <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <div className="formContainer">
              <div className="spanContainer">
                  <span className="userShowTitle">Personal Details</span>
              </div>
              <div className="formInMain">

                  <label className='myLabel'>Name</label>
                  <input
                  type="text"
                  disabled={edit}
                  // onKeyDown={handleKeyDown}
                  id="full_name"
                  value={one.full_name}
                  onChange={inputEvent}
                  className="myInput"
                  placeholder={one.full_name}
                />



                  <label className='myLabel'>E-mail</label>
                  <input type="text" readOnly disabled value={one.email} className="myInput" />


                  <label className='myLabel'>Phone</label>
                  <PhoneInput
                      country={CountryData}
                      enableSearch={true}  
                                  
                      // placeholder={user.countryCode+user.contact}
                      value={one.countryCode + one.contact}
                      disabled={edit}
                      onChange={(data,country) => setphoneuse(data,country)}
                      />


                  {/* <label className='myLabel'>Offer Price</label>
                  <input type="text"  id='offer_price'   className="myInput" /> */}


                  


                  <label className='myLabel'>Profession</label>
                  <input
                  type="text"
                  disabled={edit}
                  // onKeyDown={handleKeyDown}
                  id="designation"
                  value={one.designation}
                  onChange={inputEvent}
                  className="myInput"
                  placeholder={one.designation}
                />
                

                  <label className='myLabel'>About</label>
                  {/* <input type="text"   id='category_name'  value={one.description} className="myInput" /> */}
                  <textarea className="myInput1" disabled={edit} id='about'  value={one.about}  onChange={inputEvent} cols="20" rows="4" />
                  {/* <label className='myLabel'>Address</label>
                  <input type="text"  id='address_name' disabled={edit} value={one.address_name} onChange={inputEvent} className="myInput" /> */}
                  {/* <label className='myLabel'>Languages Speak</label>
                  <input type="text"  id='languages' disabled={edit} value={one.languages} onChange={inputEvent} className="myInput" /> */}
                  
              </div>

              <div className="spanContainer">
                  <span className="userShowTitle">Verifications</span>
              </div>

              <div className="formInMain">

                  <label className='myLabel'>Government Identity Verification</label>
                  {one.identification===false && one.ID!=="" ?  <Tooltip title="Verify" >
                    <button  className='updateDataBtn1' onClick={()=>hanldeDelete(one._id)} >
                      VERIFY
                      <GppMaybeIcon style={{color:"red"}}/>
                    </button>
                    
                  </Tooltip>: 
                  one.identification===true ? <Tooltip title="Verified" >
                    <button  className='updateDataBtn1' disabled >
                      VERIFIED
                      <GppMaybeIcon/>
                    </button>
               </Tooltip>:
               <button  className='updateDataBtn1' disabled >
               Not added yet
               <GppMaybeIcon style={{color:"black"}}/>
             </button>}
                  
               {/* superHost */}


                  <label className='myLabel'>Super host</label>
                  {one.superHost===false ?  <Tooltip title="Make host" >
                    <button  className='updateDataBtn2' onClick={()=>hanldeDeletehost(one._id)} >
                      
                      <VerifiedIcon style={{color:"red"}}/>
                    </button>
                    
                  </Tooltip>: <Tooltip title="" >
                    <button  className='updateDataBtn2' disabled >
                      
                      <VerifiedIcon/>
                    </button>
               </Tooltip>}
                  {/* <Tooltip title="Verify" >
                    <button  className='updateDataBtn2'  disabled>
                      
                      <VerifiedIcon style={{color:"red"}}/>
                    </button>
                    
                  </Tooltip> */}


                  {/* <label className='myLabel'>Email</label>
                  <input type="text" disabled id='email'   className="myInput" /> */}

              </div>

          </div>
            </Grid>
            </Grid>
            </Grid>
          </Grid>
          {/* <div className="imGCont">
              <span className='detailTitle2'>Product Images</span>
              <img src="https://icons.veryicon.com/png/o/internet--web/55-common-web-icons/person-4.png"  className='myImgprofile' alt="" /> 
          </div> */}
          

          
          
      </div>
      {/* <div className="SubmitBtnBox">
              <Tooltip title="Click to Update profile" >
                  <button  className='updateDataBtn'  >
                      Update
                  </button>
              </Tooltip>
          </div> */}
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
              <Grid item>
              <Tooltip title="Click to Update profile" >
                      <button hidden={edit} className='updateDataBtn' onClick={updateUserdata} >
                        Update
                      </button>
                    </Tooltip>
              </Grid>
              <Grid item>
              <Tooltip title="Cancel" >
                      <button hidden={edit} className='updateDataBtn' onClick={hide} >
                        Cancel
                      </button>
                    </Tooltip>
              </Grid>

            </Grid>

  </div>


</div>
}

<VerifyId
                    isDeleteOpened={delete_view}
                    body={d_userId}
                    onClick={getData}
                    CloseDialog={() => setDelete(false)}
                  />
                  <Superhost
                    isDeleteOpened={delete_viewhost}
                    body={d_userId}
                    onClick={getData}
                    CloseDialog={() => setDeletehost(false)}
                  />
  </>

)
}
