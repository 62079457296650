import './shopDetail.css'
import Toptag from '../../components/topTag/Toptag';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SwipeableTextMobileStepper from '../../components/carousel/Carousel';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/loader/Loader';
import Tooltip from '@mui/material/Tooltip';


export default function ShopDetail() {


  const location = useLocation();
  const { id } = location.state;
  console.log(id, "idddddddde")

  const [edit, setEdit] = useState('disabled');
  const [edit1, setEdit1] = useState('');

  const [one, two] = useState({
    license_img: ['a.jpg', 'b.jpg'],
    licence_verification: 0
  });
  const [isLoading, setIsLoading] = useState(false);


  const editFunction = () => {
    setEdit('')
    setEdit1('disabled')
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });

  }
  const handleChange = (event) => {
    two({
      ...one,
      [event.target.id]: event.target.value,
    });
  };

  const updateLicense = async (event) => {

    event.preventDefault();
    const updateLicStat = await axios.post('https://admin.parknb.com/admin/verifyLicense', { id: one._id, licence_verification: one.licence_verification })
    if (updateLicStat.data.status) {
      toast.success('License Verification Status Updated !', {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error('Error !', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const updateUserdata = () => {
    setEdit('disabled')
    setEdit1('')
    console.log('done')
  }

  const inputEvent = (e) => {
    const { id, value } = e.target;
    two((prevalue) => {
      return {
        ...prevalue,
        [id]: value
      };
    });
  };

  const handleKeyDown = e => {
    console.log(e.target.value)
    if (e.target.value === '') {
      if (e.key === " ") {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const getData = async () => {
      const ff = await axios.get('https://admin.parknb.com/admin/getUserDetails', { params: { id: id } });
      console.log(ff.data, "shopdetail")
      two(ff.data.data);
      if (ff) { setIsLoading(false); }

    }
    getData();
  }, [])

  return (

    <>
      {isLoading
        ?
        <Loader />
        :
        <div className='container-fluid'>
          <Toptag />
          <ToastContainer />
          <div className="userDetCont">


            <div className="userTitleContainer12">
              <span className="detailTitle">Shop Details</span>
              <button className='userAddButton' hidden={edit1} onClick={editFunction} >Edit</button>
            </div>


            <div className="mainBoxx">
              <div className="imGCont">

                <span className='detailTitle2' >License Images</span>
                <SwipeableTextMobileStepper licImg={one.license_img} />
                <div className="licUpCont">
                  <div className="labeliDiv">
                    <label className='licupLabel'>License Verification</label>
                  </div>
                  <div className="dropDown">
                    <select className="form-control" value={one.licence_verification} id='licence_verification' onChange={handleChange}  >
                      <option hidden value={0}>Not Uploaded</option>
                      <option hidden value={1}>Pending</option>
                      <option value={2}>Verified</option>
                      <option value={3}>Rejected</option>
                    </select>
                  </div>
                  <div className="btnDiv">

                    <button className='licSubmit' disabled={one.license_img ? false : true} onClick={updateLicense}>Update Status</button>
                  </div>
                </div>
              </div>

              <div className="formContainer">
                <div className="spanContainer">
                  <span className="userShowTitle">Business Details</span>
                </div>
                <div className="formInMain">


                  <label className='myLabel'>License Number</label>
                  <input type="text" readOnly disabled value={one.licenseNumber} className="myInput" />



                  <label className='myLabel'>Shop Number</label>
                  <input type="text" readOnly disabled value={one.shop_number} className="myInput" />


                  <label className='myLabel'>Timings</label>
                  <input type="text" disabled={edit} onKeyDown={handleKeyDown} onChange={inputEvent} id='timings' value={one.timings} className="myInput" />


                  <label className='myLabel'>Floors</label>
                  <input type="text" disabled={edit} onKeyDown={handleKeyDown} onChange={inputEvent} id='number_of_floors' value={one.number_of_floors} className="myInput" />


                  <label className='myLabel'>Sections</label>
                  <input type="text" disabled={edit} onKeyDown={handleKeyDown} onChange={inputEvent} id='number_of_floors_sections' value={one.number_of_floors_sections} className="myInput" />

                </div>
                <div className="spanContainer">
                  <span className="userShowTitle">Owner Details</span>
                </div>

                <div className="formInMain">

                  <label className='myLabel'>Name</label>
                  <input type="text" readOnly disabled value={one.name} className="myInput" />


                  <label className='myLabel'>User Name</label>
                  <input type="text" readOnly disabled value={one.user_name} className="myInput" />


                  <label className='myLabel'>Email</label>
                  <input type="text" readOnly disabled value={one.email} className="myInput" />
                </div>

              </div>
              
            </div>
            <div className="SubmitBtnBox">
                <Tooltip title="Click to Update profile" >
                  <button hidden={edit} className='updateDataBtn' onClick={updateUserdata} >
                    Update
                  </button>
                </Tooltip>
              </div>
          </div>
        </div>




      }
    </>




  )
}














