
import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../../components/rtEditor/Toolbar";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import server from "../../server";
import { useNavigate } from "react-router-dom";


export default function Terms() {
  const redirect = useNavigate()
  // const [state, setState] = React.useState('');
  const [one, two] = React.useState();
  const [id,setId]=React.useState("")
  // const handleChange = data => {
  //   console.log(data, 'The Data I want 7777777777777777777777777')
  //   setState(data)
  // };

  const handleChange = data => {
    console.log(data, 'val')
    two(data)
  };
 
  const updateContent = (e)=> {
    e.preventDefault();
    console.log('hi')
    let obj = { data:one, termsId:id}
    console.log(obj,"the  obj")
    axios.post(server.live_dev+'/updateTerms', obj,{headers:{"x-token":sessionStorage.getItem("x_token")}} ).then((res) => {
      if(res.data.code===203){
        toast.error(res.data.message)
        sessionStorage.clear()
        setTimeout(()=> window.location.reload(true),1000)
      }else if(res.data.code===201){
        toast.error(res.data.message)
      }else{
        loadList();
          toast.success(' Updated !', { position: toast.POSITION.TOP_RIGHT })
      }
    
      });
  }


  const loadList = () => {
    axios.get(server.live_dev+'/getTermsEdit',{headers:{"x-token":sessionStorage.getItem("x_token")}}).then((res) => {
      console.log(res.data.data, "Thhihfkjds")
      if(res.data.code===203){
        toast.error(res.data.message)
        sessionStorage.clear()
        setTimeout(()=> window.location.reload(true),1000)
      }else if(res.data.code===201){
        toast.error(res.data.message)
      }else{
        two(res.data.results.terms)
        setId(res.data.results._id)
      }
     
    });
  }

  useEffect(() => {
    loadList()
  }, [])


  return (
    <div className='container-fluid'>
            <Toptag />
            <ToastContainer />
            <div className="userDetCont">
                <div className="text-editor"  >
                    <EditorToolbar  />
                    <ReactQuill
                        style={{ height: '470px' }}
                        theme="snow"
                        id='quillState'
                        value={one}
                        onChange={handleChange}
                        placeholder={"Write something..."}
                        modules={modules}
                        formats={formats}
                    />
                </div>
                <div className="contentBtn">
                  <button  className='updateDataBtn' onClick={updateContent} style={{backgroundColor: '#cacaca', color: 'black', fontWeight: '500', fontSize: '14px'}}  >
                    SUBMIT
                  </button>
              </div>
            </div>
        </div>
  );
};



