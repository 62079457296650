import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// const data = [
//   {
//     name: 'Aug',
//     uv: 2,
//     pv: 5,
//     amt: 6,
//   },
//   {
//     name: 'Sep',
//     uv: 5,
//     pv: 1,
//     amt: 3,
//   },
//   {
//     name: 'Oct',
//     uv: 7,
//     pv: 8,
//     amt: 6,
//   },
//   {
//     name: 'Nov',
//     uv: 2,
//     pv: 3,
//     amt: 4,
//   },
//   {
//     name: 'Dec',
//     uv: 9,
//     pv: 6,
//     amt: 5,
//   }
// ];

export default function Stacked({body}) {
    const data=body;
    return (
        <div style={{ width: '100%', height: 350 ,  marginTop: '20px',minWidth: '1000px'}}>
      <ResponsiveContainer >
        <AreaChart
          width={1000}
          height={1000}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="total sale" stackId="1" stroke="#8884d8" fill="#8884d8" />
          {/* <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
          <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" /> */}
        </AreaChart>
      </ResponsiveContainer>
      </div>
    );
  
}
