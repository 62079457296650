import React, { useState } from "react";
import "../../pages/category/categories.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import server from "../../server";
import { useNavigate } from "react-router-dom";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Grid, Tooltip } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";
import { useEffect } from "react";

function Editbanner({ isDeleteOpened, onClick, CloseDialog, body ,id}) {
    
    const history = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [description, setDescription] = useState("");
    const [title,setTitle]=useState("")
    const [image, setImage] = useState("");
    const [address,setAddress]=useState("")
    const [cid, setCid] = React.useState("");
    const [item_view, setItemView] = useState(false);
    const [categories, setCategories] = useState([]);
    const [itemImage,setItemImage]= useState("")
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
      // setImage(event.target.files[0])
    };
    console.log(JSON.stringify(body),"yes your honour")
    // console.log(description,"--------------->the puthran show",address)
  //   console.log(body,"the body",age)
  
  //   const handleChange = (event) => {
  //     setCid(event.target.value);
  //     console.log(event.target.value,"the value")
  //     if(event.target.value===""){
  //         setItemView(false);
  //     }else{
  //         setItemView(true);
  //     }
      
  //   };
  

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const handleClose = () => {
      //setOpen(false);
      CloseDialog(false);
      setItemView(false);
      setCid("");
    };
  //   useEffect(() => {
  //     axios
  //       .get(server.dev + "/category_for_dropdown", {
  //         headers: { x_token: sessionStorage.getItem("x_token") },
  //       }).then((res)=>{
  //         if(res.data.code===200){
  //             setCategories(res.data.data)
  //         }
  //       })
  //   },[]);
  
    const addNew = async () => {
      if(selectedFile==="" && description.trim().length===0 && address.trim().length===0 && title.trim().length===0){
        toast.error("White spaces are not allowed check the provided data")
      }else{
        const formdata=new FormData()
        formdata.append("images",selectedFile)
        formdata.append('title',title)
        formdata.append('description',description)
        formdata.append('address',address)
        formdata.append('id',id)
        let results=await axios.post(server.live_dev+"/updateBanner",formdata,{headers:{"x-token":sessionStorage.getItem("x_token")}})
        console.log(JSON.stringify(results),"the------------------------->")
        if(results.data.code===203){
          sessionStorage.clear()
          setTimeout(()=> window.location.reload(true),1000)
        }
        if(results.data.code===201){
          toast.error(results.data.message)
        }else if(results.data.code===200){
          toast.success(results.data.message)
          CloseDialog(false);
          onClick()
          setSelectedFile("")
          setTitle("")
          setDescription("")
          setAddress("")
        }else{
          toast.error("Something went wrong!")
        }

      }     
        
    };
  
    // const onImage=(e)=>{
    //   console.log(e.target.files[0],"the file changes")
    //   if(e.target.files[0].size>3145728){
    //     toast.error("File size should be less than 3 mb")
    //   }else{
    //     setItemImage(e.target.files[0])
    //   }
    // }
  
    
    
    return (
        <div>
        <Dialog
          fullScreen={fullScreen}
          open={isDeleteOpened}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Would you like to edit this banner?"}
          </DialogTitle>
  
            <DialogContent>
            <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    onChange={(event) => setTitle(event.target.value)}
                    defaultValue={body.title}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    onChange={(event) => setDescription(event.target.value)}
                    defaultValue={body.description}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    onChange={(event) => setAddress(event.target.value)}
                    defaultValue={body.address}
                  />
                
                   <div className="image-upload">
              <label for="image">
              <Tooltip title="Upload new image"><AddPhotoAlternateIcon  style={{fontSize:"30px",color:"grey",cursor:"pointer",marginTop:"20%",marginLeft:"20%"}}/></Tooltip>
              </label>

              {/* <input id="image" hidden  type="file" onChange={(event)=>setImage(event.target.files[0])}/> */}
              <input
                  id="image"
                  style={{ display: "none" }}
                  type="file"
                  onChange={(event) => setSelectedFile(event.target.files[0])}
                  // onChange = {(event) => setImage(selectedFile)}
                  // onChange={handleFileChange}
                />
                {/* <input type="file" onChange={handleFileChange} /> */}
                {selectedFile && (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Selected Banner"
                  />
                )}
              </div>
   
 {/* <input
            id="btn-upload"
            name="btn-upload"
            // style={{ display: 'none' }}
            type="file"
            accept="image/*"
            /> */}
            </DialogContent>
          {/* ) : (
            ""
          )} */}
  
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            {/* {item_view === true ? ( */}
            <Button onClick={addNew} autoFocus>
              Update
            </Button>
            {/* ):""} */}
          </DialogActions>
        </Dialog>
      </div>
  )
}

export default Editbanner