import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { toast } from "react-toastify";
import server from "../../server";
import axios from "axios";

function Addbanner({ isDeleteOpened, onClick, CloseDialog, body }) {
  const theme = useTheme();
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDiscription] = useState("");
  const [address, setAddress] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    // setImage(event.target.files[0])
  };
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    //setOpen(false);
    CloseDialog(false);
    // setItemView(false);
    // setId("");
  };

  const create_new = async () => {
    if (
      description.trim().length === 0 ||
      address.trim().length === 0 ||
      title.trim().length === 0
    ) {
      toast.error("White spaces are not allowed check the provided data");
    } else if (selectedFile === "") {
      toast.error("Please provide an image");
    } else {
      const formdata = new FormData();
      formdata.append("images", selectedFile);
      formdata.append("title", title);
      formdata.append("description", description);
      formdata.append("address", address);
      let results = await axios.post(server.live_dev + "/addbanner", formdata, {
        headers: { "x-token": sessionStorage.getItem("x_token") },
      });
      if (results.data.code === 203) {
        sessionStorage.clear();
        setTimeout(() => window.location.reload(true), 1000);
      }
      if (results.data.code === 201) {
        toast.error(results.data.message);
      } else if (results.data.code === 200) {
        toast.success(results.data.message);
        CloseDialog(false);
        onClick();
        setImage("");
        setSelectedFile("");
        setDiscription("");
        setAddress("");
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={isDeleteOpened}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Would you like to create a new banner"}
        </DialogTitle>

        {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select one category
          </InputLabel>
        
        </FormControl> */}
        {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Autocomplete
            id="grouped-demo"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.title}
            sx={{ width: "auto" }}
            renderInput={(params) => (
              <TextField {...params} label="With categories" />
            )}
            renderGroup={(params) => (
              <li>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
          />
        </FormControl> */}

        <DialogContent>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            className="newb"
          >
            <Grid item xs={6} md={12} className="grideitem">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                onChange={(event) => setTitle(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={12} className="grideitem">
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                // type='number'
                onChange={(event) => setDiscription(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={12} className="grideitem">
              <TextField
                id="outlined-basic"
                label="Address"
                variant="outlined"
                // type='number'
                onChange={(event) => setAddress(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={11} className="grideitem">
              <div className="image-upload">
                <label for="image">
                  <Tooltip title="Upload a image">
                    <AddPhotoAlternateIcon
                      style={{
                        fontSize: "30px",
                        color: "grey",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </label>
                <input
                  id="image"
                  style={{ display: "none" }}
                  type="file"
                  onChange={(event) => setSelectedFile(event.target.files[0])}
                  // onChange = {(event) => setImage(selectedFile)}
                  // onChange={handleFileChange}
                />
                {/* <input type="file" onChange={handleFileChange} /> */}
                {selectedFile && (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Selected Banner"
                  />
                )}
              </div>
            </Grid>
            {/* <Grid item>Choose IMAGE</Grid> */}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button autoFocus onClick={create_new}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Addbanner;
