module.exports={


    server:{
        link:"https://admin.parknb.com/admin/add/"
    },

    serverforOther:{
        link:"https://admin.parknb.com/"
    }

    // serverforOther:{
    //     link:"http://localhost:3800/"
    // },

    //    server:{
    //     link:"http://localhost:3800/admin/add/"
    // }
}