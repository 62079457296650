import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import axios from 'axios';
import server from '../../server';
import { useNavigate } from 'react-router-dom';
import {  toast } from "react-toastify";


export default function Deleteproperty({ isDeleteOpened,onClick, CloseDialog ,body,amenity }) {
    
    const history =useNavigate()
    // console.log(props,"dddddd")
  // const [open, setOpen] = React.useState(props === true ? true :false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  console.log(body,"fffff")
  // const handleClickOpen = () => {
  //   setOpen(props);
  // };

  const handleClose = () => {
    //setOpen(false);
    CloseDialog(false);
  };

  const delete_submit = () =>{
    // console.log(body,"hey you---------->")
    axios.post(server.live_dev + "/deleteProperty",{propertyId:body},{headers:{"x-token":sessionStorage.getItem("x_token")}}).then((res)=>{
        if(res.data.code===203){
            sessionStorage.clear()
            toast.error(res.data.message)
            setTimeout(()=> window.location.reload(true),1000)
        } else if(res.data.code===201) {
            toast.error(res.data.message)
        } else {
          handleClose()
          onClick()
         
            toast.success(res.data.message)
        }
    })
  } 

  // useEffect(()=>{
  //   props ===  true ? setOpen(true) : setOpen(false)
  // },[props])

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isDeleteOpened}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"   
      >
        <DialogTitle id="responsive-dialog-title">
          {`Would you like to delete ${amenity}?`}
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={delete_submit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}