import * as React from "react";
import "./faq.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import {
  Add,
  Block,
  DeleteOutline,
  Email,
  RemoveRedEye,
  Search,
} from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { Avatar, Button } from "@mui/material";
import UserBlock from "../../components/modal/UserBlock";
import DeleteUser from "../../components/modal/DeleteUser";
import moment from "moment/moment";
import server from "../../server";
import Toptag from "../../components/topTag/Toptag";
import Addcategory from "../../components/modal/Addcategory";
import Deletecategory from "../../components/modal/Deletecategory";
import Additem from "../../components/modal/Additem";
// import Deleteitem from "../../components/modal/Deleteitem";
import Addfaq from "../../components/modal/Addfaq";
import Edit from "@mui/icons-material/Edit";
import Deletefaq from "../../components/modal/Deletefaq";
import Editfaq from "../../components/modal/Editfaq";
// import Deletefaq from "../../components/modal/Deletefaq";

const columns = [
  { id: "s/n", label: "", align: "right", minWidth: 12 },
  //   { id: "image", label: "", align: "right", minWidth: 12 },
  { id: "Question", label: "Question", minWidth: 170 },

  {
    id: "Answer",
    label: "Answer",
    minWidth: 170,
    align: "left",
  },
  //   { id: "category", label: "category", minWidth: 170 },
  //   {
  //     id: "Created",
  //     label: "Date created",
  //     minWidth: 170,
  //     align: 'left',
  //   },
  //   // {
  //   //   id: "isEmailVerified",
  //   //   label: "Email Verified",
  //   //   minWidth: 170,
  //   //   align: "center",
  //   // },
  {
    id: "actions",
    label: "Actions",
    minWidth: 30,
    align: "left",
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

export default function Faq() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUsersData] = useState([]);
  const [total_docs, setTotal] = useState(0);
  const [create_view, setCreate] = useState(false);
  const [delete_view, setDelete] = useState(false);
  const [edit_view, setEdit] = useState(false);
  const [fa_Id, setId] = useState("");
  const [d_userId, setDlUserId] = useState("");
  const [block_status, setblockStatus] = useState();
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState([]);
  const [question,setQuestion]= useState("")
  const [answer,setAnswer]= useState('')
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getData();
  };
let sn=page * rowsPerPage + 1 ;

  useEffect(() => {
    getData(page, rowsPerPage, search);
  }, [page, rowsPerPage, search]);

  const getData = async () => {
    // await axios
    //   .get(server.dev + "/category_for_dropdown", {
    //     headers: { x_token: sessionStorage.getItem("x_token") },
    //   }).then((res)=>{
    //     if(res.data.code===200){
    //         setCategory(res.data.data)
    //     }
    //   })
    var skip = search =="" ? page : 0
    var limit = search ==""  ? rowsPerPage : total_docs
    console.log(skip,"sfhsdkfj",limit)

    await axios
      .get(server.live_dev + "/getFaq", {
        headers: { "x-token": sessionStorage.getItem("x_token") },
        params: { pagenumber: skip, limit: limit, search: search },
      })
      .then((res) => {
        setUsersData(res.data.data);
        if (res.data.data.length > 0) {
          setIsLoading(true);
          setTotal(res.data.totalDocuments);
        }
      });
  };
  console.log(user_data, "fffff");


  const hanldeDelete = (id) => {
    setDlUserId(id);
    setDelete(!delete_view);
    getData();
  };
  const searchdata = async (e) => {
    // setSearch(e.target.value)
    console.log(e.target.value, "the value");
    await axios
      .get(server.live_dev + "/get_faq", {
        headers: { x_token: sessionStorage.getItem("x_token") },
        params: { pagenumber: page, limit: rowsPerPage, search: search },
      })
      .then((res) => {
        setUsersData(res.data.data);
        if (res.data.data.length > 0) {
          setIsLoading(true);
          setTotal(res.data.totalDocuments);
        }
      });
  };

  const edit_faq=(id,question,answer)=>{
    setId(id)
    setEdit(true)
    setQuestion(question)
    setAnswer(answer)
  }
  return (
    <>
      <Toptag />
      <ToastContainer />
      {isLoading === false ? (
        <Loader />
      ) : (
        <div>
          <div className="container-fluid">
            <div
              style={{
                marginBottom: "30px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div className="searchBarcategoryss">
                  <input
                    type="text"
                    placeholder="Search... "
                    // onKeyDown={handleKeyDown}
                    // id="searchtext"
                    onChange={(event) => setSearch(event.target.value)}
                    className="searchBarInputcate"
                  />
                  <Search className="searchIconcate" />
                </div>
              </div>
              <div>
                <button
                  className="addbtn"
                  onClick={() => setCreate(!create_view)}
                >
                  Add New FAQ
                </button>
              </div>
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 530 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user_data.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {/* <TableCell align="center">
                          {sn++}
                        </TableCell> */}
                          <TableCell>
                            {/* <Avatar
                            alt="Remy Sharp"
                            // src="https://www.spenlo.com/image/cache/catalog/products/Spenlo/Big-Onion-Savala-1Kg-700x700.jpg"
                            src={row.itemImage}
                          /> */}
                          </TableCell>
                          {/* https://th.bing.com/th/id/OIP.7c6wkFQ9XQ2jIjGYZZALrwHaE7?pid=ImgDet&rs=1 */}

                          <TableCell>{row.question}</TableCell>
                          <TableCell align="left">{row.answer}</TableCell>
                          {/* <TableCell align="left">{row.category}</TableCell> */}
                          {/* <TableCell>{row.email}</TableCell> */}
                          {/* <TableCell>{ moment.unix(row.createdAt/1000).format("MM/DD/YYYY")}</TableCell> */}

                          <TableCell align="left">
                            {/* <Tooltip title="view profile">
                            <RemoveRedEye className="visibilityIc" />
                          </Tooltip> */}
                            {/* <Link
                            to={`/sellers/details`}
                            state={{ id: row._id }}
                          >
                            <Tooltip title="view profile">
                              <RemoveRedEye className="visibilityIc" />
                            </Tooltip>
                          </Link> */}
                            {/* <Tooltip
                            title={
                              row.adminBlock === false
                                ? "block user"
                                : "unblock user"
                            }
                          >
                            <Block
                              className={`block ${"block"}`}
                              id="isActive"
                              value={row.adminBlock}
                              onClick={() => hanldeBlock(row._id,row.adminBlock)}
                            />
                          </Tooltip> */}
                            <Tooltip title="Edit FAQ">
                              <Edit
                                className="userListDelete"
                                style={{ color: "black" }}
                                onClick={() => edit_faq(row._id,row.question,row.answer)}
                              />
                            </Tooltip>
                            <Tooltip title="Delete FAQ">
                              <DeleteOutline
                                className="userListDelete"
                                onClick={() => hanldeDelete(row._id)}
                                style={{marginLeft:"1px"}}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={total_docs}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>

          <Addfaq
            isDeleteOpened={create_view}
            body={category}
            onClick={getData}
            CloseDialog={() => setCreate(false)}
          />

          <Deletefaq
            isDeleteOpened={delete_view}
            body={d_userId}
            onClick={getData}
            CloseDialog={() => setDelete(false)}
          />
          <Editfaq
            isDeleteOpened={edit_view}
            answer={answer}
            question={question}
            id={fa_Id}
            onClick={getData}
            CloseDialog={() => setEdit(false)}
          />
          {/* {
            block_view === true ? (
                <UserBlock props={block_view}/>
            ) : <UserBlock props={block_view}/>
        } */}
        </div>
      )}
    </>
  );
}
