import React from 'react'
import './amenity.css'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { ToastContainer, toast } from "react-toastify";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate ,useLocation} from "react-router-dom";
// import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import { Block, DeleteOutline, Email, RemoveRedEye, Search ,Edit,TrendingUp ,QueryStats} from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { Avatar } from "@mui/material";
import UserBlock from "../../components/modal/UserBlock";
import DeleteUser from "../../components/modal/DeleteUser";
import moment from "moment/moment";
import server from "../../server";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Toptag from '../../components/topTag/Toptag';
import Addbanner from '../../components/modal/Addbanner';
import Deletebanner from '../../components/modal/Deletebanner';
import Graph from '../../components/modal/Graph';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Graph1 from '../../components/modal/Graph1';
import Deleteproperty from '../../components/modal/Deleteproperty';
import Eventdetails from './Eventdetails';
import Deleteevent from '../../components/modal/Deleteevent';

const columns = [
  { id: "image", label: "", align: "right", minWidth: 12 },
  { id: "full_name", label: "Host", minWidth: 130 },
  { id: "event_name", label: "Event Name", maxWidth:150 },
  
  {
    id: "total_sale",
    label: "Total sale",
    minWidth: 80,
    // align: 'right',
  },
  {
    id: "price",
    label: "price",
    minWidth: 80,
    // align: 'right',
  },
  {
    id: "completedbookings",
    label: "Completed bookings",
    minWidth: 80,
    // align: 'right',
  },
  
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    align: "center",
  },
];

function Event() {
    const redirect=useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUsersData] = useState([]);
  const [total_docs, setTotal] = useState(0);
  const [block_view, setBlock] = useState(false);
  const [sale_view,setSale] = useState(false);
  const [delete_view, setDelete] = useState(false);
  const [b_userId, setUserId] = useState("");
  const [d_userId, setDlUserId] = useState("");
  const [block_status,setblockStatus]= useState()
  const [ami,setAmi]=useState("")
  const [des,setDes]=useState("")
  const [addr,setAddr]=useState([])
  const [amiId,setAmiid]=useState("")
  const [search,setSearch]= useState("")
  const [create_view, setCreate] = useState(false);
  console.log(user_data,"the foking data")
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getData();
  };
  useEffect(() => {
    getData(page, rowsPerPage,search);
  }, [page, rowsPerPage,search]);
  const getData = async () => {

    var skip = search =="" ? page : 0
    var limit = search ==""  ? rowsPerPage : total_docs
    console.log(skip,"sfhsdkfj",limit)

    await axios
      .get(server.live_dev+"/event", {
        headers: { "x-token": sessionStorage.getItem("x_token") },
        params: { pagenumber: skip, limit: limit ,search:search},
      })
      .then((res) => {
        // alert(res.data.code)
        if(res.data.code===203){
          toast.error(res.data.message)
          sessionStorage.clear()
           setTimeout(()=> window.location.reload(true),1000)
        }else if(res.data.code===201){
          toast.error(res.data.message)
        }else if(res.data.code===200){
          setUsersData(res.data.data);
          if (res.data.data.length > 0) {
            setIsLoading(true);
            setTotal(res.data.totalDocuments);
          }
        }else{
          toast.error("Something went wrong!")
        }
       
      });
  };
  const openEdit=(graph_data)=>{
    // console.log(JSON.stringify(address),"hiiii----->",JSON.stringify(description))  
    // setAmi(title)
    // setDes(description)
    setAddr(graph_data)
    // setAmiid(id)
    setBlock(true)
    // var set={
    //   description:description,
    //   address:address,
    //   title:title
    // }
  }

  const open_sales= (graph_data)=>{
    setAddr(graph_data)
    setSale(true)
  }
  const hanldeDelete = (id,ami) => {
    setDlUserId(id);
    setDelete(!delete_view);
    setAmi(ami)
    getData();
  };
  let sn=1
  return (
    <>
    {isLoading === false ? (
        <Loader />
      ) : (
        <>
        <Toptag />
        <ToastContainer />
        <div className="container-fluid"  style={{marginBottom:"30px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        
        <div className="searchBarcategorys">
            <input
              type="text"
              placeholder="Search... "
              // onKeyDown={handleKeyDown}
              id="searchtext"
              onChange={(e)=>setSearch(e.target.value)}
              className="searchBarInputcate"
            />
            <Search className="searchIconcate" />
            
          </div>
          {/* <div><button className="addbtn" onClick={()=>setCreate(!create_view)}>Add New banner</button></div> */}
        </div>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 530 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {user_data.map((row) => {
               return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  {/* https://th.bing.com/th/id/OIP.7c6wkFQ9XQ2jIjGYZZALrwHaE7?pid=ImgDet&rs=1 */}
                  <TableCell>
                          <Avatar
                            alt="Remy Sharp"
                            src={row.image ==="" ? server.user_static: server.live_image+row.image}
                          />
                        </TableCell>
                  <TableCell>{row.hostName}</TableCell>
                  <TableCell>{row.event_name.length>20 ?`${row.event_name.slice(0,20)}...` :row.event_name}</TableCell>
                  {/* a.length > 5 ? a.slice(0, 5) + "..." : a; */}
                  {/* <TableCell>{row.views}</TableCell> */}
                  
                  <TableCell>{row.total_sale}</TableCell>
                  <TableCell>{row.price}</TableCell>
                  
                  <TableCell>{row.bookings}</TableCell>
                  <TableCell align="center">
                  <Link
                            to={`/home/events/details`}
                            state={{ id: row._id}}
                          >
                            <Tooltip title="Event details">
                            <RemoveRedEye style={{color:"#4b4242", cursor: 'pointer', marginRight: '15px'}} />
                         </Tooltip>
                          </Link>
                  
                         {/* <Tooltip title={row.graph_data.length>0 ? "View booking Analytics" : "No data found"}>
                            <TrendingUp style={{color:"#75b175", cursor: 'pointer', marginRight: '15px'}} onClick={()=>row.graph_data.length>0 ? openEdit(row.graph_data):''}/>
                         </Tooltip>
                         <Tooltip title={row.graph_data.length>0 ? "View sales Analytics" : "No data found"}>
                            <QueryStats style={{color:"#5a50b4", cursor: 'pointer', marginRight: '15px'}} onClick={()=>row.graph_data.length>0 ? open_sales(row.graph_data):''}/>
                         </Tooltip> */}
                         
                         <Tooltip title="Delete event">
                            <DeleteOutline
                              className="userListDelete"
                              onClick={() => hanldeDelete(row._id,row.event_name.length>20 ?`${row.event_name.slice(0,20)}...` :row.event_name)}
                              style={{marginLeft:"10px"}}
                            />
                          </Tooltip>
                        </TableCell>
                  </TableRow>
               )
            })}
            </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={total_docs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Paper>
            <Graph 
            isDeleteOpened={block_view}
            graph_data={addr}
            id={amiId}
            onClick={getData}
            CloseDialog={() => setBlock(false)}
            />

           <Graph1 
            isDeleteOpened={sale_view}
            graph_data={addr}
            id={amiId}
            onClick={getData}
            CloseDialog={() => setSale(false)}
            />
            
             {/* <Eventdetails
            isDeleteOpened={create_view}
            body={d_userId}
            onClick={getData}
            CloseDialog={() => setCreate(false)}
          /> */}
           <Deleteevent
                    isDeleteOpened={delete_view}
                    body={d_userId}
                    amenity={ami}
                    onClick={getData}
                    CloseDialog={() => setDelete(false)}
                  />
        </>
        
      )
    }
    </>
  )
}

export default Event