import {useEffect, useState } from 'react';
import './home.css'
import { useNavigate } from "react-router-dom";
import Card from '../../components/cards/Card'
import ChartAnalysis from '../../components/chart/ChartAnalysis';




export default function Home() {
  const navigate = useNavigate();
  

  useEffect(() => {
    navigate('/home')
  }, [])
  

  return (
    <div className='home'>
        <Card/>
        <br/>
        <ChartAnalysis/>
        </div>
  )
}
