import React from "react";
import { Star, StarHalf, StarOutline } from "@mui/icons-material";
import { styled } from "@mui/material/styles";


const YellowStar = styled(Star)({
  color: "#FFD700",
});

const YellowStarHalf = styled(StarHalf)({
  color: "#FFD700",
});

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating - fullStars >= 0.5;


  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<YellowStar key={i} />);

  }
  if (halfStar) {
    stars.push(<YellowStarHalf key={fullStars} />);
  }
  const remainingStars = 5 - stars.length;
  for (let i = 0; i < remainingStars; i++) {
    stars.push(<StarOutline key={fullStars + i + 1} />);
  }
  return <div>{stars}</div>;
};

export default StarRating;
