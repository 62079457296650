import React from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

import {
  Home,
  QueryStats,
  Category,
  AccountCircle,
  TrendingUp,
  Message,
  Feedback,
  Email,
  Storefront,
  BookOnline,
  Leaderboard,
  CurrencyBitcoin,
  LocalGroceryStore,
  LocalMall,
  Loyalty,
  // SettingsApplicationsIcon,
  Flag,
  Policy,
  FindInPage,
  ReportProblem,
  Inventory,
  ProductionQuantityLimits,
  ShowerRounded,
  Celebration,
  Event,
} from "@mui/icons-material";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import CategoryIcon from "@mui/icons-material/Category";
import Groups2Icon from "@mui/icons-material/Groups2";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ReportIcon from "@mui/icons-material/Report";
import MopedIcon from "@mui/icons-material/Moped";
import DeckIcon from "@mui/icons-material/Deck";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import QuizIcon from "@mui/icons-material/Quiz";
import FeedbackIcon from "@mui/icons-material/Feedback";
import VillaIcon from "@mui/icons-material/Villa";
export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <NavLink to="/home" className="link">
              <li className="sidebarListItem">
                <Home className="sidebarIcon " />
                Home
              </li>
            </NavLink>
            {/* <NavLink to="/analytics" className="link">
              <li className="sidebarListItem">
                <QueryStats className="sidebarIcon" />
                Analytics
              </li>
            </NavLink> */}
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">User Management</h3>
          <ul className="sidebarList">
            <NavLink to="/home/user_management" className="link">
              <li className="sidebarListItem">
                <AccountCircle className="sidebarIcon" />
                Users
              </li>
            </NavLink>

            <NavLink to="/home/reported-users" className="link">
              <li className="sidebarListItem">
                <ReportIcon className="sidebarIcon" />
                Reported users
              </li>
            </NavLink>
            {/* <NavLink to='/sellers' className='link'>
                    <li className="sidebarListItem">
                    <Storefront className='sidebarIcon'/>
                    Sellers
                    </li>
                     </NavLink> */}
            {/*
                    <li className="sidebarListItem">
                    <CurrencyBitcoin className='sidebarIcon'/>
                    Transactions
                    </li>
                    <li className="sidebarListItem">
                    <Leaderboard className='sidebarIcon'/>
                    Reports
                    </li> */}
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Property management</h3>
          <ul className="sidebarList">
            <NavLink to="/home/properties" className="link">
              <li className="sidebarListItem">
                <VillaIcon className="sidebarIcon" />
                Properties
              </li>
            </NavLink>
            <NavLink to="/home/bookings" className="link">
              <li className="sidebarListItem">
                <BookOnline className="sidebarIcon" />
                Booking
              </li>
            </NavLink>
            <NavLink to="/home/propertytypes" className="link">
              <li className="sidebarListItem">
                <DeckIcon className="sidebarIcon" />
                Property Types
              </li>
            </NavLink>
            <NavLink to="/home/events" className="link">
              <li className="sidebarListItem">
                <Event className="sidebarIcon" />
                Events
              </li>
            </NavLink>
            <NavLink to="/home/aminities" className="link">
              <li className="sidebarListItem">
                <ShowerRounded className="sidebarIcon" />
                Amenities
              </li>
            </NavLink>
            <NavLink to="/home/vehicles" className="link">
              <li className="sidebarListItem">
                <MopedIcon className="sidebarIcon" />
                Vehicles
              </li>
            </NavLink>
            <NavLink to="/home/activities" className="link">
              <li className="sidebarListItem">
                <SportsFootballIcon className="sidebarIcon" />
                Activities
              </li>
            </NavLink>

            <NavLink to="/home/banners" className="link">
              <li className="sidebarListItem">
                <AddPhotoAlternateIcon className="sidebarIcon" />
                Banners
              </li>
            </NavLink>
            {/* <NavLink to='/categories' className='link'>
                    <li className="sidebarListItem">
                    <Category className='sidebarIcon'/>
                    Categories
                    </li>
                     </NavLink>
                    <NavLink to='/orders' className='link'>
                    <li className="sidebarListItem">
                    <LocalMall className='sidebarIcon'/>
                    Orders
                    </li>
                     </NavLink> */}
            {/* <NavLink to='/subscriptions' className='link'>
                    <li className="sidebarListItem">
                    <Loyalty className='sidebarIcon'/>
                    Subscription
                    </li>
                     </NavLink> */}
            {/* <NavLink to='/disputes' className='link'>
                    <li className="sidebarListItem">
                    <Flag className='sidebarIcon'/>
                    Disputes
                    </li>
                     </NavLink> */}
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Content Management</h3>
          <ul className="sidebarList">
            <NavLink to="/faq" className="link">
              <li className="sidebarListItem">
                <QuizIcon className="sidebarIcon" />
                FAQ
              </li>
            </NavLink>
            <NavLink to="/privacy_Policy" className="link">
              <li className="sidebarListItem">
                <VerifiedUserIcon className="sidebarIcon" />
                Privacy Policy
              </li>
            </NavLink>
            <NavLink to="/cancelation_Policy" className="link">
              <li className="sidebarListItem">
                <VerifiedUserIcon className="sidebarIcon" />
                Cancellation Policy
              </li>
            </NavLink>
            <NavLink to="/terms_&_Conditions" className="link">
              <li className="sidebarListItem">
                <FindInPage className="sidebarIcon" />
                T&C
              </li>
            </NavLink>
            {/* <NavLink to='/disclaimer' className='link'>
                    <li className="sidebarListItem">
                    <ReportProblem className='sidebarIcon'/>
                    Disclaimer
                    </li>
                    </NavLink> */}
            <NavLink to="/feedback" className="link">
              <li className="sidebarListItem">
                <FeedbackIcon className="sidebarIcon" />
                Feedback
              </li>
            </NavLink>
            {/* <NavLink to='/faq' className='link'>
                    <li className="sidebarListItem">
                    <QuestionAnswerIcon className='sidebarIcon'/>
                    FAQ
                    </li>
                    </NavLink> */}
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">App Setting</h3>
          <ul className="sidebarList">
            <NavLink to="/setting" className="link">
              <li className="sidebarListItem">
                <SettingsApplicationsIcon className="sidebarIcon " />
                Radius Setting
              </li>
            </NavLink>
            {/* <NavLink to="/analytics" className="link">
              <li className="sidebarListItem">
                <QueryStats className="sidebarIcon" />
                Analytics
              </li>
            </NavLink> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
