import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
// import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import { Block, DeleteOutline, Email, RemoveRedEye } from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { Avatar } from "@mui/material";
import UserBlock from "../../components/modal/UserBlock";
import DeleteUser from "../../components/modal/DeleteUser";
import moment from "moment/moment";
import server from "../../server";
import { toast } from "react-toastify";
import LockOpenIcon from '@mui/icons-material/LockOpen';

const columns = [
  { id: "profile_pic", label: "", align: "right", minWidth: 12 },
  { id: "name", label: "Name", minWidth: 150 },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    // align: 'right',
  },
  {
    id: "reported",
    label: "Reports",
    minWidth: 70,
    // align: 'right',
  },
  {
    id: "joined",
    label: "Joined since",
    minWidth: 100,
    // align: 'right',
  },
  {
    id: "isEmailVerified",
    label: "Email Verified",
    minWidth: 12,
    align: "center",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 150,
    align: "center",
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}



export default function Reportlist({body}) {
  console.log("the body....",body)
  const redirect=useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUsersData] = useState([]);
  const [total_docs, setTotal] = useState(0);
  const [block_view, setBlock] = useState(false);
  const [delete_view, setDelete] = useState(false);
  const [b_userId, setUserId] = useState("");
  const [d_userId, setDlUserId] = useState("");
  const [block_status,setblockStatus]= useState()
  const [search,setSearch]= useState(body)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getData();
  };

  useEffect(() => {
    getData(page, rowsPerPage,body);
  }, [page, rowsPerPage,body]);

  const getData = async () => {
    console.log(body,"gg")

    var skip = search =="" ? page : 0
    var limit = search ==""  ? rowsPerPage : total_docs
    console.log(skip,"sfhsdkfj",limit)

    await axios
      .get(server.live_dev+"/reportedusers", {
        headers: { "x-token": sessionStorage.getItem("x_token") },
        params: { pagenumber: skip, limit: limit ,search:body},
      })
      .then((res) => {
        // alert(res.data.code)
        if(res.data.code===203){
          toast.error(res.data.message)
          sessionStorage.clear()
           setTimeout(()=> window.location.reload(true),1000)
        }else if(res.data.code===201){
          toast.error(res.data.message)
        }else if(res.data.code===200){
          setUsersData(res.data.data);
          if (res.data.data.length > 0) {
            setIsLoading(true);
            setTotal(res.data.totalDocuments);
          }
          if (res.data.data.length === 0) {
            setIsLoading(true);
            setTotal(res.data.totalDocuments);
          }
        }else{
          toast.error("Something went wrong!")
        }
       
      });
  };
  console.log(user_data, "fffff");
  const hanldeBlock = (id,status) => {
    console.log(status,"fffff")
    setUserId(id);
    setBlock(!block_view);
    setblockStatus(status)
  };

  const hanldeDelete = (id) => {
    setDlUserId(id);
    setDelete(!delete_view);
    getData();
  };
  const redirectTo =(id)=>{
    // alert(id)
    redirect('/home/reported-users/report-details',{state:{id:id}})
  }
  return (
    <>
      {isLoading === false ? (
        <Loader />
      ) : (
        <div>
          {/* <div  className="container-fluid">

          </div> */}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 530 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user_data.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {/* https://th.bing.com/th/id/OIP.7c6wkFQ9XQ2jIjGYZZALrwHaE7?pid=ImgDet&rs=1 */}
                        <TableCell>
                          <Avatar
                            alt="Remy Sharp"
                            src={row.profile_pic ==="" ? server.user_static: server.live_image+row.profile_pic}
                          />
                        </TableCell>
                        <TableCell>{row.full_name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        {row.reported>0 ?
                        <Tooltip title="View reported details" >
                            <TableCell onClick={()=>redirectTo(row._id)}>
                          {row.reported}
                        
                        </TableCell>
                        </Tooltip>  
                        
                        :
                        <TableCell>
                          {row.reported}
                        
                        </TableCell>
                      }
                        
                        <TableCell>{ moment.unix(row.createdAt/1000).format("MM/DD/YYYY")}</TableCell>
                        <TableCell align="center">
                        {
                              row.isEmailVerified === true ? (
                                <Tooltip title="Email verified" placement="right-start">
                                  <Email
                                  className={""}
                                  style={{ color: "green", fontSize: "20px" }}
                                />
                              </Tooltip>
                             
                              ) : (
                                <Tooltip title="Email Not verified" placement="right-start">
                                <Email
                                  className={""}
                                  style={{ color: "red", fontSize: "20px" }}
                                />
                                </Tooltip>
                              )
                            }
                          {/* <Chip
                            className="chipEmail"
                            icon={
                              row.isEmailVerified === true ? (
                                <Email
                                  className={""}
                                  style={{ color: "green", fontSize: "20px" }}
                                />
                              ) : (
                                <Email
                                  className={""}
                                  style={{ color: "red", fontSize: "20px" }}
                                />
                              )
                            }
                            label={""}
                          /> */}
                        </TableCell>
                        <TableCell align="center">
                          {/* <Tooltip title="view profile">
                            <RemoveRedEye className="visibilityIc" />
                          </Tooltip> */}
                          <Link
                            to={`/users/details`}
                            state={{ id: row._id }}
                          >
                            <Tooltip title="view profile">
                              <RemoveRedEye className="visibilityIc" />
                            </Tooltip>
                          </Link>
                          <Tooltip
                            title={
                              row.adminBlock === false
                                ? "block user"
                                : "unblock user"
                            }
                          >
                            {row.adminBlock === false ?  
                              <Block
                              className={`block ${"block"}`}
                              id="isActive"
                              value={row.adminBlock}
                              onClick={() => hanldeBlock(row._id,row.adminBlock)}
                              // color="error"
                            />
                            : <LockOpenIcon
                            className={`block ${"block"}`}
                            id="isActive"
                            value={row.adminBlock}
                            onClick={() => hanldeBlock(row._id,row.adminBlock)}
                            // color="error"
                          />}
                            
                          </Tooltip>
                          <Tooltip title="Delete User">
                            <DeleteOutline
                              className="userListDelete"
                              onClick={() => hanldeDelete(row._id)}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={total_docs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          
          <div className="container-fluid">
          <UserBlock
            isDialogOpened={block_view}
            body={b_userId}
            handleCloseDialog={() => setBlock(false)}
            block_status={block_status}
            onClick={getData}
          />
          </div>
         
          <DeleteUser
            isDeleteOpened={delete_view}
            body={d_userId}
            onClick={getData}
            CloseDialog={() => setDelete(false)}
          />

          {/* {
            block_view === true ? (
                <UserBlock props={block_view}/>
            ) : <UserBlock props={block_view}/>
        } */}
        </div>
      )}
    </>
  );
}
