import React, { useState } from "react";
import "../../pages/category/categories.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import server from "../../server";
import { useNavigate } from "react-router-dom";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Grid, Tooltip } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";
import { useEffect } from "react";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

function Additem({ isDeleteOpened, onClick, CloseDialog, body }) {
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = useState("");
  const [cid, setId] = React.useState("");
  const [item_view, setItemView] = useState(false);
  const [categories, setCategories] = useState([]);
  const [image,setImage] = useState("")
  const [price,setPrice] =useState("")
//   console.log(body,"the body",age)
  
  const options = body.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const handleChange = (event) => {
    setId(event.target.value);
    console.log(event.target.value,"the value")
    if(event.target.value===""){
        setItemView(false);
    }else{
        setItemView(true);
    }
    
  };

  console.log(category, "this is that");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    //setOpen(false);
    CloseDialog(false);
    setItemView(false);
    setId("");
  };
//   useEffect(() => {
//     axios
//       .get(server.dev + "/category_for_dropdown", {
//         headers: { x_token: sessionStorage.getItem("x_token") },
//       }).then((res)=>{
//         if(res.data.code===200){
//             setCategories(res.data.data)
//         }
//       })
//   },[]);

  const addNew = async () => {
    
    if (category.trim().length <= 0)
      return toast.error("White spaces are not allowed");
    if(parseInt(price)<0 || price.trim().length <=0) return toast.error("Please provide a valid price");  
    if(image==='') return toast.error("Please add an image")
      CloseDialog(false);
    

    const formData= new FormData()
    formData.append('file',image)
    formData.append('categoryId',cid)
    formData.append('item',category)  
    formData.append('price',price)


    // toast.error("ddd")
    await axios
      .post(
        server.live_dev + "/create_item",
        formData,
        { headers: { x_token: sessionStorage.getItem("x_token") } }
      )
      .then((res) => {
        if (res.data.code === 203) {
          toast.error(res.data.message);
          sessionStorage.clear();
          setTimeout(() => history("/"), 1000);
        } else if (res.data.code === 201) {
          toast.error(res.data.message);
        } else {
          CloseDialog(false);
          toast.success(res.data.message);
          onClick();
        }
      });
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isDeleteOpened}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Would you like to create a new item"}
        </DialogTitle>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select one category
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={cid}
            label="Select one category"
            onChange={handleChange}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {body.map((row)=>(
                <MenuItem value={row._id}>{row.title}</MenuItem>
            ))}
            {/* <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
        {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Autocomplete
            id="grouped-demo"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.title}
            sx={{ width: "auto" }}
            renderInput={(params) => (
              <TextField {...params} label="With categories" />
            )}
            renderGroup={(params) => (
              <li>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
          />
        </FormControl> */}
        {item_view === true ? (
          <DialogContent>
            <Grid
              container
              spacing={3}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item className="grideitem">
                <TextField
                  id="outlined-basic"
                  label="New Item"
                  variant="outlined"
                  onChange={(event) => setCategory(event.target.value)}
                />
              </Grid>
              <Grid item className="grideitem">
                <TextField
                  id="outlined-basic"
                  label="Price"
                  variant="outlined"
                  type='number'
                  onChange={(event) => setPrice(event.target.value)}
                />
              </Grid>
              <Grid item className="grideitem">
                <div className="image-upload">
                  <label for="image">
                    <Tooltip title="Upload a image">
                      <AddPhotoAlternateIcon
                        style={{
                          fontSize: "30px",
                          color: "grey",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </label>
                  <input id="image" type="file" onChange={(event)=>setImage(event.target.files[0])}/>
                </div>
              </Grid>
              {/* <Grid item>Choose IMAGE</Grid> */}
            </Grid>
          </DialogContent>
        ) : (
          ""
        )}

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {item_view===true ? (<Button onClick={addNew} autoFocus>
            Add New
          </Button>):""}
          
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Additem;
