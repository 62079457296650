import React, {  useState } from 'react';
import './changePass.css'
import axios from 'axios';
import './profile.css';
import Toptag from '../../components/topTag/Toptag';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../../components/loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material/';
import server from '../../server';
import { useNavigate } from 'react-router-dom';

export default function ChangePass() {
   const redirect= useNavigate()
    const [inputPass, setInputPass] = useState('password');
    const [inputPass1, setInputPass1] = useState('password');
    const [inputPass2, setInputPass2] = useState('password');

    const [one, two] = useState({});
    console.log(one,"the one-------------")
    const handleKeyDown = e => {
        console.log(e.target.value)
        if (e.target.value === '') {
            if (e.key === " ") {
                e.preventDefault();
            }
        }
    };

    const inputEvent = (e) => {
        const { id, value } = e.target;
        two((prevalue) => {
            return {
                ...prevalue,
                [id]: value
            };
        });
    };

  const submitVal = async(event) => {
    event.preventDefault();
            const fi = await axios.post(server.live_dev+'/changePasswords', one ,{headers:{"x-token":sessionStorage.getItem("x_token")}})
            if(fi.data.code===203){
                toast.error(fi.data.message)
                sessionStorage.clear()
                setTimeout(()=> window.location.reload(true),1000)
              }else if(fi.data.code===201){
                toast.error(fi.data.message)
              }else{
                toast.success( fi.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
  }

    const changeInput = () => { if (inputPass === 'password') {  setInputPass('text') } else { setInputPass('password')}}
    const changeInput1 = () => { if (inputPass1 === 'password') {  setInputPass1('text') } else { setInputPass1('password')}}
    const changeInput2 = () => { if (inputPass2 === 'password') {  setInputPass2('text') } else { setInputPass2('password')}}
    

    return (
        <div className='container-fluid'>
            <Toptag />
            <ToastContainer />
            <div className="mainWrapper">
                <div className="userDetConts">
                    <div className="userTitleContainer">
                        <span className="detailTitle">Change Password</span>
                    </div>
                    <form className="userUpdateForm1"   onSubmit={submitVal}>
                        <div className="infoContainer">
                            <div className="infoTable">
                                <label className='labelCont'>Old Password</label>
                                <div className="input">
                                    <input type={inputPass} required autoComplete='off' onKeyDown={handleKeyDown} id='old_password' onChange={inputEvent} className="userInput12" />
                                    {/* {
                                        inputPass === 'password'
                                            ?
                                            < VisibilityOff onClick={changeInput} className='eyeIcon' />
                                            :
                                            < RemoveRedEye onClick={changeInput} className='eyeIcon' />
                                    } */}
                                </div>
                            </div>

                            <div className="infoTable">
                                <label className='labelCont'>New Password</label>
                                <div className="input" >
                                    <input type={inputPass1} required autoComplete='off' onKeyDown={handleKeyDown} id='new_password' onChange={inputEvent} className="userInput12" />
                                    {/* {
                                        inputPass1 === 'password'
                                            ?
                                            < VisibilityOff onClick={changeInput1} className='eyeIcon' />
                                            :
                                            < RemoveRedEye onClick={changeInput1} className='eyeIcon' />
                                    } */}
                                </div>
                            </div>
                            <div className="infoTable">
                                <label className='labelCont'>Confirm Password</label>
                                <div className="input" >
                                    <input type={inputPass2} required autoComplete='off' onKeyDown={handleKeyDown} id='confirm_password' onChange={inputEvent} className="userInput12" />
                                    {/* {
                                        inputPass2 === 'password'
                                            ?
                                            < VisibilityOff onClick={changeInput2} className='eyeIcon' />
                                            :
                                            < RemoveRedEye onClick={changeInput2} className='eyeIcon' />
                                    } */}
                                </div>
                            </div>
          

                            <div className="infoTable">
                                <Tooltip title="Click to Update profile" >
                                    <button className='updateBut1'  >
                                        Update Password
                                    </button>
                                </Tooltip>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
