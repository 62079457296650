import React from 'react'

export default function UnderConst() {
  return (
    <div>
        <h4> 🚧 Page is under construction 👷</h4>
    </div>
  )
}



// import React, { useRef, useState } from 'react';
// import './productAdd_CSV.css'
// import { Upload } from '@mui/icons-material';
// import axios from 'axios';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import Chip from '@mui/material/Chip';
// import Toptag from '../../components/topTag/Toptag';
// import { ToastContainer, toast } from 'react-toastify';


// export default function ProductAdd_CSV() {
//   const inputRef = useRef(null);
//   const [one, two] = useState({})
//   const [three, four] = useState([])
//   const [file, setFile] = useState({})
//   const [btn, setBtn] = useState({
//     btn1: false,
//     btn2: true
//   })
//   const fileReader = new FileReader();
//   const [pageSize, setPageSize] = useState(10);




//   const uploadCsv = (e) => {
//     console.log(one, 'uploaded')
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append("csv", one);
//     const config = {
//       headers: {
//         'content-type': 'multipart/form-data'
//       }
//     };

//     let obj = {}
//     axios.post('http://192.168.1.26:4000/admin/adminProduct_csv', formData, obj, config).then((res) => {
//       // axios.get('https://admin.parknb.com/admin/adminProduct_csv').then((res) => {
//       four([])
//     if(res.data.status) {
//       toast.success('Csv uploaded !', {position: toast.POSITION.TOP_RIGHT})
//     }else{
//       toast.error('Something went wrong !', {position: toast.POSITION.TOP_RIGHT
//               });
//     }
//     });
//   }


//   const handleOnChange = (e) => {
//     console.log(e.target.files[0], 'files')
//     console.log(e.target.result, 'vale')
//     setFile(e.target.files[0]);
//     two(e.target.files[0])
//   };

//   const exportCSV = (e) => {
//     console.log('coming')
//     e.preventDefault();
//     console.log(e.target.result, 'sdfsdfsdfs')

//     setBtn({btn1: true, btn2: false})
//     var csvOutput = '';
//     if (file) {
//       fileReader.onload = function (event) {
//         csvOutput = event.target.result;
      
//         var lines = csvOutput.split("\r\n");
//         var result = [];
//         var headers = lines[0].split(",");
//         for (var i = 1; i < lines.length; i++) {
//           var obj = {};
//           var currentline = lines[i].split(",");
//           for (var j = 0; j < headers.length ; j++) {
//             obj[headers[j]] = currentline[j];
//           }
//           result.push(obj);
//         }

//         for (var i = 0 ; i < result.length ; i++){
//           if(result[i].images.includes('"')){
//             result[i].images =  result[i].images.replace('"', "")
//           }
//      }
// console.log(result, "re")
//       const arr14 = result.map((item, index) => {
//         return { 'id': index + 1, ...item }
//       })
//       four(arr14)
//       };
//       fileReader.readAsText(file);
//     }
//   };

//   const columns = [
//     //  { field: 'id', headerName: 'ID', minWidth: 250 ,maxWidth: 290, flex:0.9 },
//     // { field: 'number', headerName: '#', minWidth: 100, maxWidth: 140, flex: 0.9 },

//     {
//       field: 'name', headerName: 'Name', minWidth: 280, maxWidth: 320, flex: 1,
//       renderCell: (params) => {
//         return (
//           <div className="userListUser">
//             {console.log(params.row.images, "imggggg")}
//             <img src={params.row.images} alt="" className="userListImg" />
//             {params.row.name}
//           </div>
//         )
//       }
//     },
//     {
//       field: 'quantity', headerName: 'Quantity', minWidth: 120, maxWidth: 150, flex: 3,
//       renderCell: (params) => {
//         return (
//           <div className="userListUser">

//             {params.row.quantity}
//           </div>
//         )
//       }
//     },

//     {
//       field: 'price', headerName: 'Price', minWidth: 40, maxWidth: 80, flex: 1,

//     },

//     {
//       field: 'offer_price',
//       headerName: 'Offer Price',
//       description: 'This column has a value getter and is not sortable.',
//       minWidth: 80,
//       mXWidth: 130,
//       flex: 1,

//     },
//     {
//       field: 'Brand_name',
//       headerName: 'Brand Name',
//       description: 'This column has a value getter and is not sortable.',
//       minWidth: 100,
//       mXWidth: 130,
//       flex: 1,

//     },
//     {
//       field: 'category', headerName: 'Category', minWidth: 120, maxWidth: 150, flex: 1,
//       renderCell: (params) => {
//         return (
//           <div className="userListUser">
//             {/* <img src={params.row.image} alt="" className="userListImg" />
//             {params.row.category} */}
//             <Chip className={`chipSeller`}  label={params.row.category} />
//           </div>
//         )
//       }
//     },
//     {
//       field: 'description',
//       headerName: 'Description',
//       description: 'This column has a value getter and is not sortable.',
//       minWidth: 160,
//       mXWidth: 220,
//       flex: 1,


//     },


//   ];

//   return (
//     <div className='container-fluid'>
//       <Toptag />
//       <ToastContainer />
//       <div className="mainWrapper23">
//         <div className="userDetCont23">
//           <div className="userTitleContainer32">
//             <span className="detailTitle">Add Product</span>
//           </div>
//           <form className="userUpdateForm23">
//             <div className="selectFileCont21" >
//               <div className='spandiv21'>
//                 <Upload sx={{ marginRight: '5px' }} />
//                 <input
//                  type="file"
//                  onChange={handleOnChange}
//                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//                   />
//               </div>
//             </div>
//             <div className="infoTable34">
//             <button className='updateBut233'  hidden={btn.btn1} onClick={exportCSV(ome} > IMPORT CSV </button>
//             <button className='updateBut23' hidden={btn.btn2}  onClick={uploadCsv} >UPLOAD</button>
//             </div>
//           </form>
//         </div>
//       </div >

//       {/* <div style={{ textAlign: "center" }}>
//         <h1>REACTJS CSV IMPORT EXAMPLE </h1>
//         <form>
//           <input
//             type={"file"}
//             id={"csvFileInput"}
//             accept={".csv"}
//             onChange={handleOnChange}
//           />

//           <button
//             onClick={(e) => {
//               exportCSV(e);
//             }}
//           >
//             IMPORT CSV
//           </button>
//         </form>
//       </div> */}


//       <div style={{ marginBottom: '5px' }}>
//         <span className="detailTitle2">Preview Table</span>
//       </div>


//       <div className="mainBox">
//         <div style={{ height: 430 }}>
//           <DataGrid className='gridBox'
//             sx={{
//               '.MuiDataGrid-columnHeaderTitle': {
//                 fontWeight: '600',
//                 fontSize: '15px'
//               },
//               '.MuiDataGrid-columnHeaders': {

//                 border: 'ridge'
//               }
//             }}
//             disableSelectionOnClick
//             rows={three}
//             columns={columns}
//             pageSize={pageSize}
//             onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//             rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
//           />
//         </div>
//       </div>
//     </div >



//   );

// }

