import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import axios from 'axios';
import server from '../../server';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { TextField } from '@mui/material';
import { useState } from 'react';

export default function Deletecategory({ isDeleteOpened,onClick, CloseDialog ,body ,category_name}) {
    
    const history =useNavigate()
    const [category,setCategory]= useState('')

    console.log(category,"dddddd")
  // const [open, setOpen] = React.useState(props === true ? true :false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  console.log(body,"fffff")
  // const handleClickOpen = () => {
  //   setOpen(props);
  // };

  const handleClose = () => {
    //setOpen(false);
    CloseDialog(false);
  };

  // const delete_submit = (id) =>{
  //   axios.post(server.live_dev + "/delete_category",{categoryId:id},{headers:{"x_token":sessionStorage.getItem("x_token")}}).then((res)=>{
  //       if(res.data.code===203){
  //           toast.error(res.data.message) 
  //           sessionStorage.clear()
  //           setTimeout(()=> window.location.reload(true),1000)
  //       } else if(res.data.code===201){
  //           toast.error(res.data.message)
  //       }else{
  //           handleClose()
  //           onClick()
  //           toast.success(res.data.message)
  //       }
  //   })
  // } 

  const update_category = (id)=>{
    if(category.trim().length===0) return toast.error("White spaces are not allowed")
    axios.post(server.live_dev + "/update_category",{categoryId:id,category:category},{headers:{"x_token":sessionStorage.getItem("x_token")}}).then((res)=>{
      if(res.data.code===203){
          toast.error(res.data.message) 
          sessionStorage.clear()
          setTimeout(()=> window.location.reload(true),1000)
      } else if(res.data.code===201){
          toast.error(res.data.message)
      }else{
          handleClose()
          onClick()
          toast.success(res.data.message)
      }
  })
  }

  // useEffect(()=>{
  //   props ===  true ? setOpen(true) : setOpen(false)
  // },[props])

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isDeleteOpened}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"   
      >
        <DialogTitle id="responsive-dialog-title">
          {"Would you like to update this category?"}
        </DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label={category_name}
            variant="outlined"
            placeholder={category_name}
            onChange={(event) => setCategory(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={()=>update_category(body)} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}