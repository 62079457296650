import React, { useState, useEffect } from "react";
import Server from "../../server/Server";
import "../setting/setting.css"; // Import your CSS file
import { Dna } from "react-loader-spinner";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AppSetting() {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0); // Initial value for the slider (e.g., 5000)
  const [arrowPosition, setArrowPosition] = useState(0); // Initial position for the arrow
  const [buttonHovered, setButtonHovered] = useState(false);
  const [users, setUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const history = useNavigate();
  const token = sessionStorage.getItem("x_token");

  useEffect(() => {
    const fetchDatas = async () => {
      const response = await axios.get(
        Server.server.link + "check_geonear_radius",
        {
          headers: {
            "x-token": token,
          },
        }
      );

      if (response.data.code === 201) {
        localStorage.clear();
        toast.error(response.data.message);
      } else {
        const defaultValue = response.data.radius;
        var find_the_mile = defaultValue / 1609.34;
        setValue(find_the_mile);
        setLoading(false);
      }
    };
    fetchDatas();
  }, []);

  const editRadius = async () => {
    const radius = value ; // Convert miles to meters for the API request

    try {
      const response = await axios.post(
        Server.server.link + "change_geonear_radius",
        { radius: radius },
        { headers: { "x-token": token } }
      );

      if (response.data.code === 201) {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
        setEdit(false);

        // Fetch and update the updated data without refreshing the page
        const res = await axios.get(Server.server.link + "check_geonear_radius", {
          headers: {
            "x-token": token,
          },
        });

        if (res.data.code === 203) {
          localStorage.clear();
          history.push("/");
          toast.error("Something Went Wrong");
        } else {
          setUsers(res.data.data);
          // toast.success("Updated Successfully")
        }
      }
    } catch (error) {
      console.error("Error editing radius:", error);
      toast.error("An error occurred while editing the radius.");
    }
  };

  useEffect(() => {
    const sliderWidth = 10000;
    const position = (value / sliderWidth) * 100;
    setArrowPosition(position);
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const handleButtonClick = () => {
    // Call the editRadius function when the button is clicked
    editRadius();
    document.body.style.cursor = "progress";

    setTimeout(() => {
      document.body.style.cursor = "auto";
    }, 1000);
  };

  const arrowStyle = {
    left: `${arrowPosition}%`,
  };

  const rotatedArrowStyle = {
    transform: "rotate(-90deg)",
  };

  const buttonStyle = {
    background: buttonHovered ? "#ff5722" : "#007bff",
  };

  return (
    <div className="slider-container">
      {loading ? (
        <div className="loading-animation">
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : (
        <>
          <h2>Radius (Miles)</h2>
          <div className="slider-value">
            <div
              className="slider-arrow"
              style={{ ...arrowStyle, ...rotatedArrowStyle }}
            ></div>
            <p>
              Radius: <span style={{ color: "#ff5722" }}>{value}</span> miles
            </p>
          </div>
          <div className="slider-wrapper">
            <input
              type="range"
              min="0"
              max="10000"
              value={value}
              onChange={handleChange}
              className="custom-slider"
            />
          </div>
          <button
            className="submit-button"
            style={buttonStyle}
            onClick={handleButtonClick}
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
          >
            Submit
          </button>
        </>
      )}
    </div>
  );
}

export default AppSetting;
